'use client'
import { Button, buttonVariants } from "@/components/ui/button"
import Link from "next/link"
interface IError {
    error: Error
    reset: () => void
}
const error = ({ error, reset }: IError) => {
    return <main onClick={reset} className="grid min-h-full place-items-center px-6 py-24 sm:py32 lg:px-8">
        <div className="text-center">
            <p>There was a problem</p>

            <h1 className="mt-4 text-3xl font-bold tracking-tight sm:text-5xl">
                {'Something went wrong'}
            </h1>
            <p className="mt-6 text-base leading-7 ">
                Please try again or contact support if the problem persists.
            </p>
            <div className="mt-10 flex items-center justify-center gap-x-6">
                <Button onClick={reset} variant='ghost'>Try again</Button>
                <Link href="/" className={buttonVariants({ variant: 'link' })}>
                    Go back to home
                </Link>

            </div>
        </div>

    </main>
}

export default error